var oegstgeestNav = {
  navGreenExpand: function(description) {
    var linkElementId = 'nav-green-link-' + description;
    var dropdownElementId = 'nav-green-dropdown-' + description;

    $('.nav-green-dropdown').hide();
    $('#' + dropdownElementId).slideDown('slow');

    return false;
  },
  navGreenHide: function() {
    $('.nav-green-container').hide();
  },
  navGreenShow: function() {
    $('.nav-green-container').show();
  },
  navPinkExpand: function(description) {
    var linkElementId = 'nav-pink-link-' + description;
    var dropdownElementId = 'nav-pink-dropdown-' + description;

    $('.nav-pink-dropdown').hide();
    $('#' + dropdownElementId).slideDown('slow');

    return false;
  }
}
